body {
    margin: 0%;
}

.hero-name {
    position: relative;
}

.hero-name:before {
    content: "";
    position: absolute;
    width: 0;
    height: 10px;
    bottom: 0;
    left: 0;
    background-color: #48bb78;
    visibility: hidden;
    transition: all 300ms ease-in-out;
}

.hero-name:hover:before {
    visibility: visible;
    width: 100%;
}
